import memoizeOne from 'memoize-one';

export const createPermissionsChecker =
  (permissions, isSee) => (permission, resource, obj) => {
    return isSee
      ? permissions.canSee(permissions.resourcify(obj, resource), permission)
      : permissions.can(permissions.resourcify(obj, resource), permission);
  };

const createPermissionsRenderHelper =
  (canChecker, canSeeChecker) => (permission, resource, obj, render) => {
    const renderFunc = render ? render : obj;

    const can = canChecker(permission, resource, obj);
    const canSee = canSeeChecker(permission, resource, obj);
    return can || canSee ? renderFunc(can, canSee) : null;
  };

/*
Which permissions checker should I use?

* can - use when you only care about if user can do action or not - using this carries a message
  that action is visible only when user can actually do it.
* canSee - use it when you want to show that user could do an action, even
  if he currently can't. For example - like a post as logged in, but private member.
  Using this should be accompanied by decorating for user to login or become a public member.
* canRender - use it everywhere where you render UI based on permissions. It checks
  both can and canSee.

*/

const create = (permissions) => {
  const can = createPermissionsChecker(permissions, false);
  const canSee = createPermissionsChecker(permissions, true);
  const canRender = createPermissionsRenderHelper(can, canSee);
  return { can, canSee, canRender };
};

const createMemoized = memoizeOne(create, ([a], [b]) => a._key === b._key);

export default function createPermissionHelpers(permissions) {
  return permissions._key ? createMemoized(permissions) : create(permissions);
}
