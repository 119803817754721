import { createSelector } from 'reselect';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';
import {
  getSocialSharingProviders,
  getIsCommentsEnabled,
} from './app-settings-selectors';

export const getPermissionFlags = createSelector(
  [getIsMemberAreaInstalled, getSocialSharingProviders, getIsCommentsEnabled],
  (isMemberAreaInstalled, providers, isCommentsEnabled) => ({
    isMemberAreaInstalled,
    isSocialSharingEnabled: providers.length > 0,
    isCommentsEnabled,
  }),
);
