import { useSelector } from '../components/runtime-context';
import {
  getIsBlogMenuEnabled,
  getIsBlogMenuCategoryLabelsEnabled,
  getIsBlogMenuSearchEnabled,
  getIsBlogMenuLoginButtonEnabled,
} from '../selectors/app-settings-selectors';

const useBlogMenuSettings = () => {
  return useSelector((state) => ({
    showBlogMenu: getIsBlogMenuEnabled(state),
    showCategoryLabels: getIsBlogMenuCategoryLabelsEnabled(state),
    showSearch: getIsBlogMenuSearchEnabled(state),
    showLoginButton: getIsBlogMenuLoginButtonEnabled(state),
  }));
};

export default useBlogMenuSettings;
