import { BLOG_HEADER_TOTAL_RESULTS } from '@wix/communities-blog-client-common';
import { getHeader } from './get-header';

export const getTotalResults = (headers) =>
  parseInt(getHeader(headers, BLOG_HEADER_TOTAL_RESULTS), 10);

export const formatTitle = ({ title, page, lastPage, t }) =>
  page < 2
    ? title
    : `${title} | ${t('pagination.current-position-tile', { page, lastPage })}`;
