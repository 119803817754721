import React from 'react';
import Loader from '../loader';
import styles from './animated-loader.scss';

type Props = {
  shouldShowLoader?: boolean;
  isLoading: boolean;
  children?: React.ReactNode;
};

const AnimatedLoader: React.FC<Props> = ({
  shouldShowLoader = true,
  isLoading,
  children,
}) => (
  <div className={styles.container}>
    {shouldShowLoader && isLoading ? (
      <div key="loader" className={styles.loaderBox}>
        <Loader />
      </div>
    ) : null}
    {isLoading ? null : <div key="content">{children}</div>}
  </div>
);

export default AnimatedLoader;
