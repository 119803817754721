import { isEmpty } from 'lodash';
import { AppState } from '../../../types';

const getModalState = (state: AppState) => state.modal;

export const getModal = (state: AppState, id: string) =>
  getModalState(state).openedModals[id];

export const getOpenedModals = (state: AppState) =>
  getModalState(state).openedModals;

export const getIsModalDisplayed = (state: AppState, id: string) =>
  !isEmpty(id ? getOpenedModals(state) : getModal(state, id));
