import { get } from 'lodash';
import {
  getDefaultSiteLocale,
  getLocales,
} from '@wix/communities-blog-client-common';
import { getBasicParams } from '../store/basic-params/basic-params-selectors';

export const getLocale = (state) => get(state, 'locale.current');
export const getQueryLocale = (state) => get(state, 'locale.query');

export function getLanguageCode(state) {
  const locale = getQueryLocale(state);
  const language = getBasicParams(state).language;
  const languageCode =
    locale ||
    language ||
    (getLocales(state) && getDefaultSiteLocale(state)?.id) ||
    null;

  return languageCode;
}
