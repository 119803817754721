import React, { PropsWithChildren, useContext } from 'react';
import { createPortal } from 'react-dom';

const PortalElementContext = React.createContext<HTMLDivElement | null>(null);

export function TooltipPortalProvider(props: PropsWithChildren<{}>) {
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  return (
    <PortalElementContext.Provider value={tooltipRef.current}>
      {props.children}
      <div ref={tooltipRef} data-hook="tooltip-portal"></div>
    </PortalElementContext.Provider>
  );
}

export function TooltipPortal(props: PropsWithChildren<{}>) {
  const tooltip = useTooltip();

  return tooltip.createPortal(props.children);
}

function useTooltip() {
  const portalElement = useContext(PortalElementContext);

  return {
    createPortal: (children: React.ReactNode) =>
      portalElement ? createPortal(children, portalElement) : null,
  };
}
