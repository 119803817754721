import React from 'react';
import PropTypes from 'prop-types';
import { getTPASettignsLiveSiteEditingDeprecated } from '@wix/communities-blog-client-common';
import { connect } from '../../../common/components/runtime-context';
import { getLayoutPostSize } from '../../../common/selectors/app-settings-selectors';
import CreatePostButtonDesktop from './create-post-button-desktop';
import PageTabs from './page-tabs';
import PageTitle from './page-title';
import styles from './my-posts-frame-desktop.scss';

const MyPostsFrameDesktop = ({
  children,
  layoutPostSize,
  isLiveSiteEditingEnabled,
}) => (
  <section
    className={styles.root}
    style={{ '--width': layoutPostSize ? `${layoutPostSize}px` : undefined }}
  >
    <div className={styles.contentWrapper}>
      <header className={styles.header}>
        <PageTitle />
        {isLiveSiteEditingEnabled && <CreatePostButtonDesktop />}
      </header>
      <main className={styles.tabs}>
        <PageTabs />
      </main>
      {children}
    </div>
  </section>
);

MyPostsFrameDesktop.propTypes = {
  layoutPostSize: PropTypes.number.isRequired,
  isLiveSiteEditingEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, _actions, host) => ({
  layoutPostSize: getLayoutPostSize({
    state,
    hostWidth: host.dimensions.width || undefined,
  }),
  isLiveSiteEditingEnabled: !getTPASettignsLiveSiteEditingDeprecated(state),
});

export default connect(mapRuntimeToProps)(MyPostsFrameDesktop);
